import api from './api';

class StudentsService {
  get(id) {
    return api.get(`/students/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/students', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/students`, data).then((res) => res.data);
  }

  preCreate(data) {
    return api.post(`/students/pre-create`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/students/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/students/${id}`).then((res) => res.data);
  }
}

export default new StudentsService();
